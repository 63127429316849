import {createSlice} from '@reduxjs/toolkit';


// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

const initialState = {
    isLoading: false,
    error: null,
    sellers: [],
    seller: null,
};

const slice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getSellerSuccess(state, action) {
            state.isLoading = false;
            state.seller = action.payload;
        },
        getSellersSuccess(state, action) {
            state.isLoading = false;
            state.sellers = action.payload;
        },
        resetCurrentSeller(state, action) {
            state.seller = null
        },
        updateSellerListAfterDeletion: (state, action) => {
            const sellerIdToDelete = action.payload;
            state.sellers = state.sellers.filter((seller) => seller._id !== sellerIdToDelete);
        },
    },
});

export default slice.reducer;

export const {
    startLoading,
    hasError,
    getSellerSuccess,
    getSellersSuccess,
    resetCurrentSeller,
    updateSellerListAfterDeletion
} = slice.actions;

export function createSeller(sellerData) {
    return async () => {
        dispatch(startLoading());
        try {
            const response = await axios.post('/api/sellers', sellerData);
            dispatch(slice.actions.getSellerSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error.toString()));
            return Promise.reject(error);
        }
    };
}

export function updateSeller(sellerData, id) {
    return async () => {
        dispatch(startLoading());
        try {
            const response = await axios.patch(`/api/sellers/${id}`, sellerData);
            dispatch(slice.actions.getSellerSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error.toString()));
            return Promise.reject(error);
        }
    };
}

export function deleteSeller(id) {
    return async () => {
        dispatch(startLoading());
        try {
            const response = await axios.delete(`/api/sellers/${id}`);
            dispatch(slice.actions.updateSellerListAfterDeletion(id));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error.toString()));
            return Promise.reject(error);
        }
    };
}

export function getSellers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/sellers`);
            dispatch(slice.actions.getSellersSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error.toString()));
            return Promise.reject(error);
        }
    };
}

export function getSeller(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/sellers/${id}`);
            dispatch(slice.actions.getSellerSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error.toString()));
            return Promise.reject(error);
        }
    };
}

