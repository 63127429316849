import _ from 'lodash';
import {paramCase} from 'change-case';
import {useState, useEffect} from 'react';
import {useSnackbar} from "notistack";
import {Link as RouterLink} from "react-router-dom";


// @mui
import {
    Box,
    Card,
    Table,
    Button,
    Switch,
    Tooltip,
    TableBody,
    Container,
    IconButton,
    TableContainer,
    TablePagination,
    FormControlLabel, Checkbox,
} from '@mui/material';

// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {PATH_DASHBOARD} from "../../routes/paths";
import Iconify from "../../components/Iconify";

import useSettings from "../../hooks/useSettings";
import {ProductTableRow, ProductTableToolbar} from "../../sections/@dashboard/e-commerce/product-list";
import useTable, {emptyRows, getComparator} from "../../hooks/useTable";
import {useDispatch, useSelector} from "../../redux/store";
import {
    TableNoData,
    TableSkeleton,
    TableEmptyRows,
    TableHeadCustom,
    TableSelectedActions,
} from '../../components/table';
import Scrollbar from "../../components/Scrollbar";
import {getProductSellerList, updateProduct, updateProductsSellers} from "../../redux/slices/product";
import ProductSellerTableRow from "../../sections/@dashboard/e-commerce/ProductSellerTableRow";
import {getSellers} from "../../redux/slices/seller";


export default function ProductSellerList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({
        defaultOrderBy: 'createdAt',
    });
    const dispatch = useDispatch();
    const {themeStretch} = useSettings();
    const {productsSellers, isLoading: isLoadingProducts} = useSelector((state) => state.product);
    const {sellers, isLoading: isLoadingSellers} = useSelector((state) => state.seller);
    const {enqueueSnackbar} = useSnackbar();

    const [tableData, setTableData] = useState([]);
    const [sellerIdList, setSellerIdList] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [productsSellersData, setProductsSellersData] = useState([]);
    const [tableLabel, setTableLabel] = useState([])

    useEffect(() => {
        dispatch(getProductSellerList());
        dispatch(getSellers());
    }, [dispatch]);

    useEffect(() => {
        if (sellers.length) {
            const sellerIds = []
            const label = sellers.map(seller => {
                sellerIds.push(seller._id)
                return {
                    id: seller._id,
                    label: seller.name,
                    showCheckbox: true,
                    // minWidth: seller.name.length > 10 ? seller.name.length * 14 : seller.name.length * 17
                }
            })

            setSellerIdList(sellerIds)
            setTableLabel((prevTableLabel) => [{id: 'g2a_name', label: 'Название', align: 'left'}, ...label]);

        }
    }, [sellers]);

    useEffect(() => {
        if (productsSellers.length) {
            setTableData(productsSellers);
        }
    }, [productsSellers]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const dataFiltered = applySortFilter({
        tableData,
        comparator: getComparator(order, orderBy),
        filterName,
    });

    const onSelectSeller = (sellerRef, checked) => {
        const startIndex = page * rowsPerPage
        const lastIndex = startIndex + rowsPerPage
        const updatedDataFiltered = _.cloneDeep(dataFiltered);

        for (let i = startIndex; i < lastIndex; i) {
            const row = updatedDataFiltered[i]

            if (!row) break
            if (!row?.sellerRefs) {
                row.sellerRefs = []
            }

            const sellerRefs = _.cloneDeep(row.sellerRefs)

            if (!row.sellerRefs.includes(sellerRef) && checked) {
                row.sellerRefs.push(sellerRef);
            } else if (!checked && row.sellerRefs.includes(sellerRef)) {
                row.sellerRefs = row.sellerRefs.filter((id) => id !== sellerRef);
            }


            const updatedSellers = row.sellerRefs
            const productRef = row._id

            const deletedSellers = sellerRefs.filter((id) => !updatedSellers.includes(id))
            const addedSellers = updatedSellers.filter((id) => !sellerRefs.includes(id))

            onChangeProductSellers({
                productRef,
                deletedSellers,
                addedSellers,
                updatedSellers
            })


            i += 1
        }

        setTableData(updatedDataFiltered);
    }

    const onChangeProductSellers = (data) => {
        // Проверяем, существует ли объект с таким productRef
        const existingIndex = productsSellersData.findIndex((item) => item.productRef === data.productRef);

        if (existingIndex !== -1) {
            // Обновляем существующий объект, если он найден
            if (data.deletedSellers.length === 0 && data.addedSellers.length === 0) {
                // Если оба массива пусты, удаляем объект из массива
                setProductsSellersData((prevData) => prevData.filter((item) => item.productRef !== data.productRef));
            } else {
                // Иначе обновляем объект
                setProductsSellersData((prevData) => {
                    const newData = [...prevData];
                    newData[existingIndex] = data;
                    return newData;
                });
            }

        } else {
            // Добавляем новый объект, если productRef не найден
            setProductsSellersData((prevData) => [...prevData, data]);
        }
    };

    const onSave = async () => {
        try {
            setProductsSellersData([])
            const products = await dispatch(updateProductsSellers(productsSellersData));
            enqueueSnackbar('Список продавцов обновлен!', {variant: 'success'});
            setTableData(products)
        } catch (error) {
            let message = 'Ошибка при обработке формы!'
            if (error?.response?.data?.message) {
                message = error.response.data.message
            } else {
                message = error.message
            }
            enqueueSnackbar(message, {variant: 'error'});
        }
    }

    const denseHeight = dense ? 60 : 80;

    const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoadingProducts && !dataFiltered.length);

    return (
        <Page title="Список продавцов">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Список продавцов"
                    links={[
                        {name: 'Dashboard', href: PATH_DASHBOARD.root},
                        {
                            name: 'Продукты',
                            href: PATH_DASHBOARD.product.root,
                        },
                        {name: 'Список продавцов'},
                    ]}
                    action={
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:checkmark-circle-outline"/>}
                            onClick={onSave}
                        >
                            Сохранить
                        </Button>
                    }
                />

                <Card>
                    <ProductTableToolbar filterName={filterName} onFilterName={handleFilterName}/>

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom headLabel={tableLabel} onSelectSeller={onSelectSeller}/>

                                <TableBody>
                                    {(isLoadingProducts ? [...Array(rowsPerPage)] : dataFiltered)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) =>
                                            row ? (
                                                <ProductSellerTableRow
                                                    key={row._id}
                                                    row={row}
                                                    selected={selected.includes(row._id)}
                                                    sellerIdList={sellerIdList}
                                                    onChangeProductSellers={onChangeProductSellers}
                                                />
                                            ) : (
                                                !isNotFound && <TableSkeleton key={index} sx={{height: denseHeight}}/>
                                            )
                                        )}

                                    <TableEmptyRows height={denseHeight}
                                                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}/>

                                    <TableNoData isNotFound={isNotFound}/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>


                    <Box sx={{position: 'relative'}}>
                        <TablePagination
                            rowsPerPageOptions={[15, 25, 50, 100]}
                            component="div"
                            count={dataFiltered.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense}/>}
                            label="Уменьшить расстояние между строками"
                            sx={{px: 3, py: 1.5, top: 0, position: {md: 'absolute'}}}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    )
}


function applySortFilter({tableData, comparator, filterName}) {
    const stabilizedThis = tableData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        tableData = tableData.filter((item) => {
            const name = item?.g2a_name || item?.name || ''
            return name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        });
    }

    return tableData;
}
