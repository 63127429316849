import {useEffect, useState} from "react";
import {Checkbox, IconButton, MenuItem, TableCell, TableRow, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {fDate} from "../../../utils/formatTime";
import Label from "../../../components/Label";
import {fCurrency} from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import {TableMoreMenu} from "../../../components/table";


export default function ProductSellerTableRow({row, sellerIdList, onChangeProductSellers}) {
    // const {name, g2a_name, _id, sellerRefs} = row;
    const name = row?.name
    const g2a_name = row?.g2a_name
    const _id = row?._id
    const sellerRefs = row?.sellerRefs || []

    const [selectedSellers, setSelectedSellers] = useState(sellerRefs);

    useEffect(() => {
        // Если значения sellerRefs изменяются извне, обновим selectedSellers
        if (sellerRefs.length > 0) {
            setSelectedSellers(sellerRefs);
        }
    }, [sellerRefs]);

    const handleCheckboxChange = (productRef, sellerId) => {
        const updatedSellers = selectedSellers.includes(sellerId)
            ? selectedSellers.filter((id) => id !== sellerId)
            : [...selectedSellers, sellerId];

        const deletedSellers = sellerRefs.filter((id) => !updatedSellers.includes(id))
        const addedSellers = updatedSellers.filter((id) => !sellerRefs.includes(id))
        onChangeProductSellers({
            productRef,
            deletedSellers,
            addedSellers,
            updatedSellers
        })

        setSelectedSellers(updatedSellers);

        // Здесь вы можете добавить код для отправки данных на сервер или другие действия
    };

    return (
        <TableRow>
            <TableCell sx={{maxWidth: 300}}>
                <Typography variant="subtitle2" component={RouterLink} to={PATH_DASHBOARD.product.view(_id)}>
                    {g2a_name || name || _id}
                </Typography>
            </TableCell>
            {sellerIdList.map(sellerId => (
                <TableCell key={sellerId} padding="checkbox">
                    <Checkbox
                        checked={selectedSellers.includes(sellerId)}
                        onChange={() => handleCheckboxChange(_id, sellerId)}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}