// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  setting: getIcon('ic_setting'),
};

const navConfig = [
  {
    subheader: 'Управление',
    items: [
      {
        title: 'Продукты',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.cart,
        children: [
          { title: 'Список', path: PATH_DASHBOARD.product.list },
          { title: 'Продавцы', path: PATH_DASHBOARD.product.sellers },
          { title: 'Добавить', path: PATH_DASHBOARD.product.new },
        ],
      },
      {
        title: 'Дропшиппинг',
        path: PATH_DASHBOARD.dropshipping.root,
        icon: ICONS.cart,
        children: [
          { title: 'Список', path: PATH_DASHBOARD.dropshipping.list },
          { title: 'Добавить', path: PATH_DASHBOARD.dropshipping.new },
        ],
      },
      {
        title: 'Продавцы',
        path: PATH_DASHBOARD.seller.root,
        icon: ICONS.user,
        children: [
          { title: 'Список', path: PATH_DASHBOARD.seller.list },
          { title: 'Добавить', path: PATH_DASHBOARD.seller.new },
        ],
      },
      {
        title: 'Ордера',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.invoice,
      },
      {
        title: 'Настройки',
        path: PATH_DASHBOARD.setting.root,
        icon: ICONS.setting,
      },
    ],
  },
  // {
  //     subheader: 'Шаблоны',
  //     items: [
  //         {
  //             title: 'Список',
  //             children: [
  //                 {title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard},
  //                 {title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce},
  //                 {title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics},
  //                 {title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking},
  //                 {title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking},
  //                 {title: 'shop', path: PATH_DASHBOARD.eCommerce.shop},
  //                 {title: 'e-product', path: PATH_DASHBOARD.eCommerce.demoView},
  //                 {title: 'list', path: PATH_DASHBOARD.eCommerce.list},
  //                 {title: 'create', path: PATH_DASHBOARD.eCommerce.new},
  //                 {title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit},
  //                 {title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout},
  //                 {title: 'profile', path: PATH_DASHBOARD.user.profile},
  //                 {title: 'cards', path: PATH_DASHBOARD.user.cards},
  //                 {title: 'list', path: PATH_DASHBOARD.user.list},
  //                 {title: 'create', path: PATH_DASHBOARD.user.new},
  //                 {title: 'edit', path: PATH_DASHBOARD.user.demoEdit},
  //                 {title: 'account', path: PATH_DASHBOARD.user.account},
  //                 {title: 'list', path: PATH_DASHBOARD.invoice.list},
  //                 {title: 'details', path: PATH_DASHBOARD.invoice.demoView},
  //                 {title: 'create', path: PATH_DASHBOARD.invoice.new},
  //                 {title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit},
  //                 {title: 'posts', path: PATH_DASHBOARD.blog.posts},
  //                 {title: 'post', path: PATH_DASHBOARD.blog.demoView},
  //                 {title: 'create', path: PATH_DASHBOARD.blog.new},
  //                 {title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat},
  //                 {title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar},
  //                 {title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban},
  //             ]
  //         }
  //     ]
  // }
];
export default navConfig;
