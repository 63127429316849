import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, Checkbox, TableCell, Typography, MenuItem, IconButton } from '@mui/material';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { PATH_DASHBOARD } from '../../../../routes/paths';
//

// ----------------------------------------------------------------------

ProductTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ProductTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const {
    name,
    active,
    createdAt,
    averageCost,
    g2a_name,
    _id,
    availableKeys,
    soldKeys,
    income,
    soldThisMonth,
    platform,
    region,
    g2aOfferPosition,
  } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ maxWidth: 300 }}>
        <Typography variant="subtitle2" component={RouterLink} to={PATH_DASHBOARD.product.view(_id)}>
          {g2a_name || name || _id}
        </Typography>
      </TableCell>

      <TableCell>{g2aOfferPosition}</TableCell>
      <TableCell>{fDate(createdAt)}</TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={active ? 'success' : 'warning'}
          sx={{ textTransform: 'capitalize' }}
        >
          {active ? 'Активен' : 'Не активен'}
        </Label>
      </TableCell>
      <TableCell align="right">{region}</TableCell>
      <TableCell align="right">{platform}</TableCell>
      <TableCell align="right">{fCurrency(averageCost)}</TableCell>
      <TableCell align="right">{availableKeys < 0 ? 0 : availableKeys}</TableCell>
      <TableCell align="right">{soldKeys}</TableCell>
      <TableCell align="right">{soldThisMonth}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(event) => {
            handleOpenMenu(event);
          }}
        >
          <Iconify icon={'eva:more-vertical'} />
        </IconButton>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Редактировать
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Удалить
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
