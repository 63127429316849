import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  productsSellers: [],
  keys: [],
  offers: [],
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetCurrentProduct(state) {
      state.product = null;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startG2aOffersLoading(state) {
      state.g2aOffersIsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCTS
    getProductsSellersSuccess(state, action) {
      state.isLoading = false;
      state.productsSellers = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getProductKeysSuccess(state, action) {
      state.isLoading = false;
      state.keys = action.payload;
    },

    getProductOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
    },

    getProductKinguinOfferSuccess(state, action) {
      state.isLoading = false;
      state.kinguinOffer = action.payload;
    },

    getProductKinguinDropShippingOfferSuccess(state, action) {
      state.isLoading = false;
      state.kinguinDropShippingOffer = action.payload;
    },

    setProductKinguinOfferSpaData(state, action) {
      state.isLoading = false;
      state.spa = action.payload;
    },

    deleteProductKinguinOfferSuccess(state) {
      state.isLoading = false;
      state.kinguinOffer = null;
    },

    getG2aProductOffersSuccess(state, action) {
      state.g2aOffersIsLoading = false;
      state.g2aOffers = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetCurrentProduct,
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------
export function getProducts(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products', {
        params
      });
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductSellerList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/product/sellers');
      dispatch(slice.actions.getProductsSellersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(productId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/${productId}`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// Передача `dispatch` как параметра функции
export function createProduct(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/products', productData);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function createProductKeys(productId, keysData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/${productId}/keys`, keysData);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getProductKeys(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/${productId}/keys`);
      dispatch(slice.actions.getProductKeysSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getProductsTags() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/products/tags`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getProductOffers(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/${productId}/offers`);
      dispatch(slice.actions.getProductOffersSuccess(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getG2aProductOffers(productId, reload = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startG2aOffersLoading());
    try {
      const url = reload
        ? `/api/products/g2a/offers/${productId}?reload=true`
        : `/api/products/g2a/offers/${productId}`;
      const response = await axios.get(url);
      dispatch(slice.actions.getG2aProductOffersSuccess(response.data));

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getProductKinguinOffer(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/kinguin/${productId}`);
      dispatch(slice.actions.getProductKinguinOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function addProductKinguinOffer(productId, kinguinProductId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/kinguin/${productId}`, { productId: kinguinProductId });
      dispatch(slice.actions.getProductKinguinDropShippingOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function enableKinguinDropShipping(productId, kinguinProductId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/${productId}/dropshipping/kinguin`, { productId: kinguinProductId });
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function disableKinguinDropShipping(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/${productId}/dropshipping/kinguin/disable`);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function getProductKinguinOfferSpa(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/kinguin/${productId}/spa`);
      dispatch(slice.actions.setProductKinguinOfferSpaData(response.data));
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function activateSpa(productId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/kinguin/${productId}/spa`, data);
      // dispatch(slice.actions.getProductKinguinOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function updateSpa(productId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/products/kinguin/${productId}/spa`, data);
      // dispatch(slice.actions.getProductKinguinOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function deactivateSpa(productId) {
  return async (dispatch) => {
    try {
      console.log(productId);
      const response = await axios.delete(`/api/products/kinguin/${productId}/spa`);
      // dispatch(slice.actions.getProductKinguinOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function deleteProductKinguinOffer(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/products/kinguin/${productId}`);
      dispatch(slice.actions.deleteProductKinguinOfferSuccess());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return Promise.reject(error);
    }
  };
}

export function updateProduct(productId, productData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/api/products/${productId}`, productData);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}

export function startDump(productId, productData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/${productId}/dumper`, productData);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}

export function updateProductsSellers(productsSellersData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/api/product/sellers`, productsSellersData);
      dispatch(slice.actions.getProductKinguinOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}

export function deleteProduct(productId, productData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/products/${productId}`, productData);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}

export function deleteKeys(productId, ids) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/products/${productId}/keys/remove`, { ids });
      dispatch(slice.actions.getProductSuccess(response.data.product));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}

export async function fetchProducts(name, region, platform, activeOffers) {
  const response = await axios.get('/api/search/products', {
    params: {
      name,
      region,
      platform,
      activeOffers,
    },
  });
  return response.data;
}

export async function fetchKinguinProducts(phrase, region, platform) {
  // Если фраза поиска не предоставлена, не выполняем поиск
  if (!phrase) {
    // Возвращаем пустой массив, null или выбрасываем исключение, в зависимости от требований вашего приложения
    return []; // или null, или throw new Error("Search phrase is required");
  }

  // Инициализируем параметры запроса
  const params = { phrase };

  // Добавляем параметры, если они существуют
  if (region) {
    params.regions = region;
  }
  if (platform) {
    params.platforms = platform;
  }

  // Выполняем запрос к API
  const response = await axios.get('/api/products/kinguin/search', { params });

  // Возвращаем данные
  return response.data;
}

export function getG2aProductOfferSellers(productId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/g2a/offers/sellers/${productId}`);
      return response.data.result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getProductSalesToday(productId, currentSeller) {
  try {
    const response = await axios.get(`/api/order/sales/product/${productId}/today`, {
      params: { sellerId: currentSeller },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getProductSalesDateRange(productId, startDate, endDate, currentSeller) {
  try {
    const response = await axios.get(`/api/order/sales/product/${productId}/days`, {
      params: {
        startDate: new Date(new Date(startDate).setHours(0, 0, 0, 0)),
        endDate: new Date(new Date(endDate).setHours(23, 59, 59, 999)),
        sellerId: currentSeller,
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getG2aProductSales(productId) {
  try {
    const response = await axios.get(`/api/products/stats/${productId}`);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getProductSales(productId) {
  try {
    const response = await axios.get(`/api/order/sales/product/${productId}`);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function downloadExcel(params) {
  const URL = '/api/order/sales/product/group';
  try {
    const response = await axios.get(URL, {
      params,
      responseType: 'blob', // Указываем, что ожидаем получить binary data
    });

    // Создаем временную ссылку для скачивания файла
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.xlsx'); // Задаем имя файла для скачивания
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export async function getOrder(orderId) {
  try {
    const response = await axios.get(`/api/order/${orderId}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function findOrder(key) {
  const response = await axios.get(`/api/order/search/`, {
    params: {
      key,
    },
  });

  return response.data;
}
